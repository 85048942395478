import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

interface ToggleButtonProps {
  disabled?: boolean;
  toggleColor?: string;
  border?: string;
}

export const ToggleButtonContainer = styled.label<ToggleButtonProps>`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 17px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: ${({ border }) => border};
    opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #ccc;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 1px;
    bottom: ${({ border }) => (border === 'none' ? '2px' : '1px')};
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: ${({ toggleColor }) => toggleColor};
    border: ${({ border, toggleColor }) => border !== 'none' && `1px solid ${toggleColor}`};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ToggleLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

interface ToggleButtonTitleProps {
  disabled?: boolean;
  color?: string;
}

export const ToggleButtonTitle = styled.label<ToggleButtonTitleProps>`
  color: ${({ color }) => color || '#FFFFFF'};
  font-size: 15px;
  line-height: 20px;
  margin-right: 10px;
  white-space: nowrap;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  ${BREAKPOINTS.mobile} {
    font-size: 14px;
    margin-right: 8px;
  }
`;
