import React, { createContext } from 'react';

export interface SideDrawerRef {
  onClose?: () => void;
  setConfirmBeforeClose?: (message: string) => void;
  onCloseAttempt?: () => void;
  onCancelClose?: () => void;
  onConfirmClose?: () => void;
}

export interface SideDrawerInfo {
  sideDrawerRef: React.RefObject<SideDrawerRef>;
  setShouldConfirmBeforeClose?: (message: string) => void;
  shouldConfirmBeforeClose?: string;
}

export const SideDrawerContext = createContext<SideDrawerInfo>({
  sideDrawerRef: { current: null },
  setShouldConfirmBeforeClose: () => {},
  shouldConfirmBeforeClose: null,
});
