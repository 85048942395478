import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export interface INLPFound {
  industries?: INLPDataType[];
  skills?: INLPDataType[];
  passions?: INLPDataType[];
  clients?: INLPDataType[];
  projects?: INLPDataType[];
  people?: INLPPerson[];
}

export interface INLPDataType {
  name: string;
  uuid: string;
  original_name: string;
  type?: string;
  aliases: string[];
}

export interface INLPPerson {
  name: string;
  uuid: string;
  slug: string;
  role_name?: string;
  level_name?: string;
  company_name: string;
  company_slug: string;
  avatar?: string;
  city?: string;
  linkedin: any;
  slack: any;
  email?: string;
  connection_count: number;
  cross_kyu_teaming: boolean;
  is_freelancer: boolean;
}

export interface INLPState {
  found: INLPFound;
  notFound: string;
  prompt: string;
  prompt_uuid: string;
  feedback: string;
  showSearchbar: boolean;
  isLoading: boolean;
}

const initialState = {
  showSearchbar: true,
  found: null,
  notFound: null,
  prompt: null,
  prompt_uuid: null,
  feedback: null,
  isLoading: false,
};

export const slice = createSlice<INLPState, SliceCaseReducers<INLPState>>({
  name: 'nlp',
  initialState,
  reducers: {
    setShowSearchbar: (state, action) => {
      state.showSearchbar = action.payload;
    },
    setFound: (state, action) => {
      state.found = action.payload;
    },
    setNotFound: (state, action) => {
      state.notFound = action.payload;
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    setPromptUuid: (state, action) => {
      state.prompt_uuid = action.payload;
    },
    setFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    setIsNLPLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setShowSearchbar,
  setFound,
  setNotFound,
  setPrompt,
  setPromptUuid,
  setFeedback,
  setIsNLPLoading,
} = slice.actions;

export const showSearchbarSelector = (state) => state.nlp.showSearchbar;
export const foundSelector = (state): INLPFound => state.nlp.found;
export const notFoundSelector = (state) => state.nlp.notFound;
export const promptSelector = (state) => state.nlp.prompt;
export const promptUuidSelector = (state) => state.nlp.prompt_uuid;
export const feedbackSelector = (state) => state.nlp.feedback;
export const isNLPLoadingSelector = (state) => state.nlp.isLoading;

export default slice.reducer;
