import { PayloadAction, SliceCaseReducers, createSelector, createSlice } from '@reduxjs/toolkit';
import { DataTypePillType } from 'app/components/CommonStyled/DataTypePill/DataTypePill';
import { behavioralObjectTypeCorrectionMap } from 'utils/constants';

export const PEOPLE_FILTER_TYPE = {
  LEVELS: 'levels',
  CURRENT_COMPANY: 'companies',
  PREVIOUSLY_WORKED_AT: 'prior_companies',
  INDUSTRIES: 'industries',
  SKILLS: 'kyu_skills',
  DISCIPLINES: 'disciplines',
  ROLES: 'roles',
  CLIENTS: 'clients',
  PASSIONS: 'passions',
  COMMUNITIES: 'communities',
  LOCATIONS: 'locations',
  LANGUAGES: 'languages',
  TENURE: 'tenure',
  WORK_EXPERIENCE: 'work_experience',
  PROJECT_LEADS: 'project_leads',
  CONTRIBUTED_RESOURCES: 'contributed_resources',
  HOSTED_EVENTS: 'hosted_events',
  OTHER_COMPANIES: 'other_companies',
  ONLY_COLLEAGUES: 'my_company',
  EXCLUDE_COLLEAGUES: 'other_companies',
  INCLUDE_FREELANCERS: 'company_freelancers',
  AVAILABLE_CROSS_KYU_TEAMING: 'cross_kyu_teaming',
} as const;

export type IPeopleFilterType = (typeof PEOPLE_FILTER_TYPE)[keyof typeof PEOPLE_FILTER_TYPE];

export const PEOPLE_CONTEXT = {
  COLLECTIVE_DIRECTORY: 'collective_directory',
  TEAMING: 'teaming',
} as const;

export type IPeopleContext = (typeof PEOPLE_CONTEXT)[keyof typeof PEOPLE_CONTEXT];

export type IPeopleExclusiveFilterType =
  | typeof PEOPLE_FILTER_TYPE.PROJECT_LEADS
  | typeof PEOPLE_FILTER_TYPE.CONTRIBUTED_RESOURCES
  | typeof PEOPLE_FILTER_TYPE.HOSTED_EVENTS
  | typeof PEOPLE_FILTER_TYPE.OTHER_COMPANIES
  | typeof PEOPLE_FILTER_TYPE.ONLY_COLLEAGUES
  | typeof PEOPLE_FILTER_TYPE.EXCLUDE_COLLEAGUES
  | typeof PEOPLE_FILTER_TYPE.INCLUDE_FREELANCERS
  | typeof PEOPLE_FILTER_TYPE.AVAILABLE_CROSS_KYU_TEAMING;

export type IPeopleSortType = 'relevance' | 'name';
export interface IPerson {
  name?: string;
  full_name: string;
  slug: string;
  uuid: string;
  role_name: string;
  level_name: string;
  company_name: string;
  company_slug: string;
  avatar: string;
  city: string;
  connection_count: number;
  work_experience: string;
  is_freelancer: boolean;
  primary_teaming_leader: {
    uuid: string;
    name: string;
    avatar: string;
    role_name: string;
    company_name: string;
    company_slug: string;
  };
}

export interface IPeopleFilter {
  name?: string;
  uuid: string;
}

export interface IPeopleFilters {
  [PEOPLE_FILTER_TYPE.LEVELS]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.CURRENT_COMPANY]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.PREVIOUSLY_WORKED_AT]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.INDUSTRIES]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.SKILLS]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.DISCIPLINES]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.ROLES]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.CLIENTS]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.PASSIONS]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.COMMUNITIES]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.LOCATIONS]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.LANGUAGES]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.TENURE]?: IPeopleFilter[];
  [PEOPLE_FILTER_TYPE.WORK_EXPERIENCE]?: IPeopleFilter[];
}

export interface IPeopleDetails {
  company_freelancers: number;
  cross_kyu_teaming: number;
  my_company: number;
}

export interface IPeopleOnboarding {
  client: {
    uuid: string;
    name: string;
  };
  skill: {
    uuid: string;
    name: string;
  };
  industry: {
    uuid: string;
    name: string;
  };
  discipline: {
    uuid: string;
    name: string;
  };
  passion: {
    uuid: string;
    name: string;
  };
  company: {
    uuid: string;
    name: string;
  };
}

export interface IPeopleUuids {
  uuid: string;
  name: string;
  matches: number;
  matched_elements: string[];
  matched_detail: {
    name: string;
    type: DataTypePillType;
  }[];
}

export interface IPeopleExclusiveFilters {
  [PEOPLE_FILTER_TYPE.PROJECT_LEADS]?: boolean;
  [PEOPLE_FILTER_TYPE.CONTRIBUTED_RESOURCES]?: boolean;
  [PEOPLE_FILTER_TYPE.HOSTED_EVENTS]?: boolean;
  [PEOPLE_FILTER_TYPE.OTHER_COMPANIES]?: boolean;
  [PEOPLE_FILTER_TYPE.ONLY_COLLEAGUES]?: boolean;
  [PEOPLE_FILTER_TYPE.EXCLUDE_COLLEAGUES]?: boolean;
  [PEOPLE_FILTER_TYPE.INCLUDE_FREELANCERS]?: boolean;
  [PEOPLE_FILTER_TYPE.AVAILABLE_CROSS_KYU_TEAMING]?: boolean;
}

export interface PeopleState {
  context: IPeopleContext;
  details: IPeopleDetails;
  filters: IPeopleFilters;
  filters_length: number;
  previous_filters: IPeopleFilters;
  previous_filters_length: number;
  exclusive_filters: IPeopleExclusiveFilters;
  people: IPerson[];
  original_people: IPerson[];
  default_people: IPerson[];
  selected_people: IPerson[];
  uuids: IPeopleUuids[];
  default_uuids: IPeopleUuids[];
  should_fetch: boolean;
  current_page: number;
  should_get_more_people: boolean;
  is_people_loading: boolean;
  is_more_people_loading: boolean;
  force_dropdown_filter_open: boolean;
  current_selected_person: IPerson | null;
  show_contact_drawer: boolean;
  people_error: boolean;
  sort: IPeopleSortType;
  open_mobile_nav: boolean;
  current_filter_submenu_open: IPeopleFilterType | null;
  show_beacons: boolean;
  current_beacon_step: number;
  show_onboarding_modal: boolean;
  force_scroll_sidebar: boolean;
  should_show_beacons: boolean;
  show_teaming_contact_drawer: boolean;
  selected_criteria: {
    [key in DataTypePillType]?: string[];
  };
  force_skeleton_list: boolean;
}

const exclusiveFilterToTitle = new Map<IPeopleFilterType, string>([
  [PEOPLE_FILTER_TYPE.PROJECT_LEADS, `they're project leads`],
  [PEOPLE_FILTER_TYPE.OTHER_COMPANIES, `they're not from your company`],
  [PEOPLE_FILTER_TYPE.CONTRIBUTED_RESOURCES, `they contributed resources`],
  [PEOPLE_FILTER_TYPE.HOSTED_EVENTS, `they hosted events`],
  [PEOPLE_FILTER_TYPE.ONLY_COLLEAGUES, `they're your colleagues`],
  [PEOPLE_FILTER_TYPE.INCLUDE_FREELANCERS, `they're freelancers`],
]);

const getFiltersLength = (
  filters: IPeopleFilters,
  exclusive_filters: IPeopleExclusiveFilters
): number => {
  let allInclusiveFilters = 0;
  let allExclusiveFilters = 0;

  if (filters) {
    allInclusiveFilters = Object.values(filters)?.reduce((acc, filter) => {
      if (!filter) return acc;
      return acc + filter?.length;
    }, 0);
  }

  if (exclusive_filters) {
    allExclusiveFilters = Object.values(exclusive_filters)?.reduce(
      (acc, filter) => (filter ? acc + 1 : acc),
      0
    );
  }

  return allInclusiveFilters + allExclusiveFilters;
};

export const slice = createSlice<PeopleState, SliceCaseReducers<PeopleState>>({
  name: 'people',
  initialState: {
    context: null,
    details: {
      company_freelancers: 0,
      cross_kyu_teaming: 0,
      my_company: 0,
    },
    filters: {
      [PEOPLE_FILTER_TYPE.LEVELS]: [],
      [PEOPLE_FILTER_TYPE.CURRENT_COMPANY]: [],
      [PEOPLE_FILTER_TYPE.PREVIOUSLY_WORKED_AT]: [],
      [PEOPLE_FILTER_TYPE.INDUSTRIES]: [],
      [PEOPLE_FILTER_TYPE.SKILLS]: [],
      [PEOPLE_FILTER_TYPE.DISCIPLINES]: [],
      [PEOPLE_FILTER_TYPE.ROLES]: [],
      [PEOPLE_FILTER_TYPE.CLIENTS]: [],
      [PEOPLE_FILTER_TYPE.PASSIONS]: [],
      [PEOPLE_FILTER_TYPE.COMMUNITIES]: [],
      [PEOPLE_FILTER_TYPE.LOCATIONS]: [],
      [PEOPLE_FILTER_TYPE.LANGUAGES]: [],
      [PEOPLE_FILTER_TYPE.TENURE]: [],
      [PEOPLE_FILTER_TYPE.WORK_EXPERIENCE]: [],
    },
    filters_length: 0,
    previous_filters: {
      [PEOPLE_FILTER_TYPE.LEVELS]: [],
      [PEOPLE_FILTER_TYPE.CURRENT_COMPANY]: [],
      [PEOPLE_FILTER_TYPE.PREVIOUSLY_WORKED_AT]: [],
      [PEOPLE_FILTER_TYPE.INDUSTRIES]: [],
      [PEOPLE_FILTER_TYPE.SKILLS]: [],
      [PEOPLE_FILTER_TYPE.DISCIPLINES]: [],
      [PEOPLE_FILTER_TYPE.ROLES]: [],
      [PEOPLE_FILTER_TYPE.CLIENTS]: [],
      [PEOPLE_FILTER_TYPE.PASSIONS]: [],
      [PEOPLE_FILTER_TYPE.COMMUNITIES]: [],
      [PEOPLE_FILTER_TYPE.LOCATIONS]: [],
      [PEOPLE_FILTER_TYPE.LANGUAGES]: [],
      [PEOPLE_FILTER_TYPE.TENURE]: [],
      [PEOPLE_FILTER_TYPE.WORK_EXPERIENCE]: [],
    },
    exclusive_filters: {
      [PEOPLE_FILTER_TYPE.PROJECT_LEADS]: false,
      [PEOPLE_FILTER_TYPE.CONTRIBUTED_RESOURCES]: false,
      [PEOPLE_FILTER_TYPE.HOSTED_EVENTS]: false,
      [PEOPLE_FILTER_TYPE.OTHER_COMPANIES]: false,
      [PEOPLE_FILTER_TYPE.ONLY_COLLEAGUES]: false,
      [PEOPLE_FILTER_TYPE.INCLUDE_FREELANCERS]: false,
      [PEOPLE_FILTER_TYPE.AVAILABLE_CROSS_KYU_TEAMING]: false,
    },
    previous_filters_length: 0,
    people: [],
    original_people: [],
    default_people: [],
    selected_people: [],
    uuids: [],
    default_uuids: [],
    should_fetch: false,
    current_page: 1,
    should_get_more_people: false,
    is_people_loading: false,
    is_more_people_loading: false,
    force_dropdown_filter_open: false,
    current_selected_person: null,
    show_contact_drawer: false,
    people_error: false,
    sort: 'relevance',
    open_mobile_nav: false,
    current_filter_submenu_open: null,
    show_beacons: false,
    current_beacon_step: 1,
    show_onboarding_modal: false,
    force_scroll_sidebar: false,
    should_show_beacons: false,
    show_teaming_contact_drawer: false,
    selected_criteria: null,
    force_skeleton_list: false,
  },
  reducers: {
    setContext: (state, action: PayloadAction<IPeopleContext>) => {
      if (action.payload !== state.context) {
        state.context = action.payload;
      }
    },
    setDetails: (state, action: PayloadAction<IPeopleDetails>) => {
      state.details = action.payload;
    },
    addFilter: (
      state,
      action: { payload: { type: IPeopleFilterType; filters: IPeopleFilter[] } }
    ) => {
      if (!state.filters[action.payload.type]) {
        state.filters[action.payload.type] = [];
      }

      state.filters[action.payload.type] = [
        ...state.filters[action.payload.type],
        ...action.payload.filters,
      ];

      state.filters_length = getFiltersLength(state.filters, state.exclusive_filters);
    },
    setExclusiveFilter: (
      state,
      action: { payload: { type: IPeopleExclusiveFilterType; state: boolean } }
    ) => {
      state.exclusive_filters[action.payload.type] = action.payload.state;
      state.filters_length = getFiltersLength(state.filters, state.exclusive_filters);
    },
    setExclusiveFilters: (state, action: { payload: { filters: IPeopleExclusiveFilters } }) => {
      state.exclusive_filters = action.payload.filters;
      state.filters_length = getFiltersLength(state.filters, state.exclusive_filters);
    },
    setFilter: (
      state,
      action: { payload: { type: IPeopleFilterType; filters: IPeopleFilter[] } }
    ) => {
      state.filters[action.payload.type] = action.payload.filters;
      state.filters_length = getFiltersLength(state.filters, state.exclusive_filters);
    },
    setFilters: (
      state,
      action: { payload: { filters: IPeopleFilters; exclusive_filters: IPeopleExclusiveFilters } }
    ) => {
      state.filters = {
        [PEOPLE_FILTER_TYPE.LEVELS]: action.payload.filters[PEOPLE_FILTER_TYPE.LEVELS] || [],
        [PEOPLE_FILTER_TYPE.CURRENT_COMPANY]:
          action.payload.filters[PEOPLE_FILTER_TYPE.CURRENT_COMPANY] || [],
        [PEOPLE_FILTER_TYPE.PREVIOUSLY_WORKED_AT]:
          action.payload.filters[PEOPLE_FILTER_TYPE.PREVIOUSLY_WORKED_AT] || [],
        [PEOPLE_FILTER_TYPE.INDUSTRIES]:
          action.payload.filters[PEOPLE_FILTER_TYPE.INDUSTRIES] || [],
        [PEOPLE_FILTER_TYPE.SKILLS]: action.payload.filters[PEOPLE_FILTER_TYPE.SKILLS] || [],
        [PEOPLE_FILTER_TYPE.DISCIPLINES]:
          action.payload.filters[PEOPLE_FILTER_TYPE.DISCIPLINES] || [],
        [PEOPLE_FILTER_TYPE.ROLES]: action.payload.filters[PEOPLE_FILTER_TYPE.ROLES] || [],
        [PEOPLE_FILTER_TYPE.CLIENTS]: action.payload.filters[PEOPLE_FILTER_TYPE.CLIENTS] || [],
        [PEOPLE_FILTER_TYPE.PASSIONS]: action.payload.filters[PEOPLE_FILTER_TYPE.PASSIONS] || [],
        [PEOPLE_FILTER_TYPE.COMMUNITIES]:
          action.payload.filters[PEOPLE_FILTER_TYPE.COMMUNITIES] || [],
        [PEOPLE_FILTER_TYPE.LOCATIONS]: action.payload.filters[PEOPLE_FILTER_TYPE.LOCATIONS] || [],
        [PEOPLE_FILTER_TYPE.LANGUAGES]: action.payload.filters[PEOPLE_FILTER_TYPE.LANGUAGES] || [],
        [PEOPLE_FILTER_TYPE.TENURE]: action.payload.filters[PEOPLE_FILTER_TYPE.TENURE] || [],
        [PEOPLE_FILTER_TYPE.WORK_EXPERIENCE]:
          action.payload.filters[PEOPLE_FILTER_TYPE.WORK_EXPERIENCE] || [],
      };
      state.exclusive_filters = {
        [PEOPLE_FILTER_TYPE.PROJECT_LEADS]:
          action.payload.exclusive_filters?.[PEOPLE_FILTER_TYPE.PROJECT_LEADS] || false,
        [PEOPLE_FILTER_TYPE.CONTRIBUTED_RESOURCES]:
          action.payload.exclusive_filters?.[PEOPLE_FILTER_TYPE.CONTRIBUTED_RESOURCES] || false,
        [PEOPLE_FILTER_TYPE.HOSTED_EVENTS]:
          action.payload.exclusive_filters?.[PEOPLE_FILTER_TYPE.HOSTED_EVENTS] || false,
        [PEOPLE_FILTER_TYPE.OTHER_COMPANIES]:
          action.payload.exclusive_filters?.[PEOPLE_FILTER_TYPE.OTHER_COMPANIES] || false,
        [PEOPLE_FILTER_TYPE.ONLY_COLLEAGUES]:
          action.payload.exclusive_filters?.[PEOPLE_FILTER_TYPE.ONLY_COLLEAGUES] || false,
        [PEOPLE_FILTER_TYPE.INCLUDE_FREELANCERS]:
          action.payload.exclusive_filters?.[PEOPLE_FILTER_TYPE.INCLUDE_FREELANCERS] || false,
        [PEOPLE_FILTER_TYPE.AVAILABLE_CROSS_KYU_TEAMING]:
          action.payload.exclusive_filters?.[PEOPLE_FILTER_TYPE.AVAILABLE_CROSS_KYU_TEAMING] ||
          false,
      };
      state.filters_length = getFiltersLength(state.filters, state.exclusive_filters);
    },
    removeFromFilter: (
      state,
      action: { payload: { type: IPeopleFilterType; filters: IPeopleFilter[] } }
    ) => {
      const filtersUuids = action.payload.filters.map((f) => f.uuid);

      state.filters[action.payload.type] = state.filters[action.payload.type].filter(
        (filter) => !filtersUuids.includes(filter.uuid)
      );

      state.filters_length = getFiltersLength(state.filters, state.exclusive_filters);
    },
    emptyFilter: (state, action: { payload: IPeopleFilterType }) => {
      state.filters[action.payload] = [];
      state.filters_length = getFiltersLength(state.filters, state.exclusive_filters);
    },
    emptyAllFilters: (state) => {
      state.filters = {
        [PEOPLE_FILTER_TYPE.LEVELS]: [],
        [PEOPLE_FILTER_TYPE.CURRENT_COMPANY]: [],
        [PEOPLE_FILTER_TYPE.PREVIOUSLY_WORKED_AT]: [],
        [PEOPLE_FILTER_TYPE.INDUSTRIES]: [],
        [PEOPLE_FILTER_TYPE.SKILLS]: [],
        [PEOPLE_FILTER_TYPE.DISCIPLINES]: [],
        [PEOPLE_FILTER_TYPE.ROLES]: [],
        [PEOPLE_FILTER_TYPE.CLIENTS]: [],
        [PEOPLE_FILTER_TYPE.PASSIONS]: [],
        [PEOPLE_FILTER_TYPE.COMMUNITIES]: [],
        [PEOPLE_FILTER_TYPE.LOCATIONS]: [],
        [PEOPLE_FILTER_TYPE.LANGUAGES]: [],
        [PEOPLE_FILTER_TYPE.TENURE]: [],
        [PEOPLE_FILTER_TYPE.WORK_EXPERIENCE]: [],
      };
      state.previous_filters = {
        [PEOPLE_FILTER_TYPE.LEVELS]: [],
        [PEOPLE_FILTER_TYPE.CURRENT_COMPANY]: [],
        [PEOPLE_FILTER_TYPE.PREVIOUSLY_WORKED_AT]: [],
        [PEOPLE_FILTER_TYPE.INDUSTRIES]: [],
        [PEOPLE_FILTER_TYPE.SKILLS]: [],
        [PEOPLE_FILTER_TYPE.DISCIPLINES]: [],
        [PEOPLE_FILTER_TYPE.ROLES]: [],
        [PEOPLE_FILTER_TYPE.CLIENTS]: [],
        [PEOPLE_FILTER_TYPE.PASSIONS]: [],
        [PEOPLE_FILTER_TYPE.COMMUNITIES]: [],
        [PEOPLE_FILTER_TYPE.LOCATIONS]: [],
        [PEOPLE_FILTER_TYPE.LANGUAGES]: [],
        [PEOPLE_FILTER_TYPE.TENURE]: [],
        [PEOPLE_FILTER_TYPE.WORK_EXPERIENCE]: [],
      };
      state.exclusive_filters = {
        [PEOPLE_FILTER_TYPE.PROJECT_LEADS]: false,
        [PEOPLE_FILTER_TYPE.CONTRIBUTED_RESOURCES]: false,
        [PEOPLE_FILTER_TYPE.HOSTED_EVENTS]: false,
        [PEOPLE_FILTER_TYPE.OTHER_COMPANIES]: false,
      };
      state.filters_length = 0;
      state.previous_filters_length = 0;
    },
    updatePreviousFilters: (state, action: PayloadAction<null>) => {
      state.previous_filters = { ...state.filters };
      state.previous_filters_length = state.filters_length;
    },
    setPeople: (state, action: PayloadAction<IPerson[]>) => {
      state.people = action.payload;
    },
    setOriginalPeople: (state, action: PayloadAction<IPerson[]>) => {
      state.original_people = action.payload;
    },
    setDefaultPeople: (state, action: PayloadAction<IPerson[]>) => {
      state.default_people = action.payload;
    },
    pushPeople: (state, action: PayloadAction<IPerson[]>) => {
      state.people = [...state.people, ...action.payload];
    },
    setSelectedPeople: (state, action: PayloadAction<IPerson[]>) => {
      state.selected_people = action.payload;
    },
    pushSelectedPeople: (state, action: PayloadAction<IPerson[]>) => {
      state.selected_people = [...state.selected_people, ...action.payload];
    },
    removeSelectedPeople: (state, action: PayloadAction<IPerson[]>) => {
      state.selected_people = state.selected_people.filter(
        (person) => !action.payload.some((p) => p.uuid === person.uuid)
      );
    },
    setUuids: (state, action: PayloadAction<IPeopleUuids[]>) => {
      state.uuids = action.payload;
    },
    setDefaultUuids: (state, action: PayloadAction<IPeopleUuids[]>) => {
      state.default_uuids = action.payload;
    },
    setShouldFetch: (state, action: PayloadAction<boolean>) => {
      state.open_mobile_nav = false;
      state.current_page = 1;
      state.should_fetch = action.payload;
    },
    setShouldGetMorePeople: (state, action: PayloadAction<boolean>) => {
      state.should_get_more_people = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.current_page = action.payload;
    },
    setIsPeopleLoading: (state, action: PayloadAction<boolean>) => {
      state.is_people_loading = action.payload;
    },
    setIsMorePeopleLoading: (state, action: PayloadAction<boolean>) => {
      state.is_more_people_loading = action.payload;
    },
    setForceDropdownFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.force_dropdown_filter_open = action.payload;
    },
    setCurrentSelectedPerson: (state, action: PayloadAction<IPerson | null>) => {
      state.current_selected_person = action.payload;
    },
    setShowContactDrawer: (state, action: PayloadAction<boolean>) => {
      state.show_contact_drawer = action.payload;
    },
    setShowTeamingContactDrawer: (state, action: PayloadAction<boolean>) => {
      state.show_teaming_contact_drawer = action.payload;
    },
    setSort: (state, action: PayloadAction<IPeopleSortType>) => {
      state.sort = action.payload;
    },
    setPeopleError: (state, action: PayloadAction<boolean>) => {
      state.people_error = action.payload;
    },
    setOpenMobileNav: (state, action: PayloadAction<boolean>) => {
      state.open_mobile_nav = action.payload;
    },
    setCurrentFilterSubmenuOpen: (state, action: PayloadAction<IPeopleFilterType | null>) => {
      state.current_filter_submenu_open = action.payload;
    },
    setCurrentBeaconStep: (state, action: PayloadAction<number>) => {
      state.current_beacon_step = action.payload;
    },
    setShowBeacons: (state, action: PayloadAction<boolean>) => {
      state.show_beacons = action.payload;
    },
    setShowOnboardingModal: (state, action: PayloadAction<boolean>) => {
      state.show_onboarding_modal = action.payload;
    },
    setForceScrollSidebar: (state, action: PayloadAction<boolean>) => {
      state.force_scroll_sidebar = action.payload;
    },
    setShouldShowBeacons: (state, action: PayloadAction<boolean>) => {
      state.should_show_beacons = action.payload;
    },
    setSelectedCriteria: (
      state,
      action: PayloadAction<{ [key in DataTypePillType]: string[] }>
    ) => {
      state.selected_criteria = action.payload;
    },
    pushSelectedCriteria: (
      state,
      action: PayloadAction<{ type: DataTypePillType; name: string }>
    ) => {
      if (!state.selected_criteria || !state.selected_criteria[action.payload.type]) {
        state.selected_criteria = {
          ...state.selected_criteria,
          [action.payload.type]: [action.payload.name],
        };
      } else {
        state.selected_criteria[action.payload.type].push(action.payload.name);
      }
    },
    removeSelectedCriteria: (
      state,
      action: PayloadAction<{ type: DataTypePillType; name: string }>
    ) => {
      state.selected_criteria[action.payload.type] = state.selected_criteria[
        action.payload.type
      ].filter((criteria) => criteria !== action.payload.name);

      if (state.selected_criteria[action.payload.type].length === 0) {
        delete state.selected_criteria[action.payload.type];
      }

      if (Object.keys(state.selected_criteria).length === 0) {
        state.selected_criteria = null;
      }
    },
    setForceSkeletonList: (state, action: PayloadAction<boolean>) => {
      state.force_skeleton_list = action.payload;
    },
  },
});

export const {
  setContext,
  setDetails,
  addFilter,
  setExclusiveFilter,
  setExclusiveFilters,
  setFilter,
  removeFromFilter,
  emptyFilter,
  emptyAllFilters,
  updatePreviousFilters,
  setUuids,
  setDefaultUuids,
  setPeople,
  setDefaultPeople,
  pushPeople,
  setSelectedPeople,
  pushSelectedPeople,
  removeSelectedPeople,
  setShouldFetch,
  setShouldGetMorePeople,
  setCurrentPage,
  setIsPeopleLoading,
  setIsMorePeopleLoading,
  setForceDropdownFilterOpen,
  setCurrentSelectedPerson,
  setShowContactDrawer,
  setShowTeamingContactDrawer,
  setPeopleError,
  setSort,
  setOpenMobileNav,
  setCurrentFilterSubmenuOpen,
  setCurrentBeaconStep,
  setShowBeacons,
  setShowOnboardingModal,
  setFilters,
  setForceScrollSidebar,
  setShouldShowBeacons,
  setSelectedCriteria,
  pushSelectedCriteria,
  removeSelectedCriteria,
  setForceSkeletonList,
  setOriginalPeople,
} = slice.actions;

export const detailsSelector = (state): IPeopleDetails => state.people.details;
export const filtersSelector = (state): IPeopleFilters => state.people.filters;
export const exclusiveFiltersSelector = (state): IPeopleExclusiveFilters =>
  state.people.exclusive_filters;
export const previousFiltersSelector = (state): IPeopleFilters => state.people.previous_filters;
export const selectedPeopleSelector = (state): IPerson[] => state.people.selected_people;
export const uuidsSelector = (state): IPeopleUuids[] => state.people.uuids;
export const defaultUuidsSelector = (state): IPeopleUuids[] => state.people.default_uuids;
export const filtersLengthSelector = (state): number => state.people.filters_length;
export const previousFiltersLengthSelector = (state): number =>
  state.people.previous_filters_length;
export const shouldFetchSelector = (state): boolean => state.people.should_fetch;
export const shouldGetMorePeopleSelector = (state): boolean => state.people.should_get_more_people;
export const currentPageSelector = (state): number => state.people.current_page;
export const isPeopleLoadingSelector = (state): boolean => state.people.is_people_loading;
export const isMorePeopleLoadingSelector = (state): boolean => state.people.is_more_people_loading;
export const forceDropdownFilterOpenSelector = (state): boolean =>
  state.people.force_dropdown_filter_open;
export const currentSelectedPersonSelector = (state): IPerson | null =>
  state.people.current_selected_person;
export const showContactDrawerSelector = (state): boolean => state.people.show_contact_drawer;
export const showTeamingContactDrawerSelector = (state): boolean =>
  state.people.show_teaming_contact_drawer;
export const peopleErrorSelector = (state): boolean => state.people.people_error;
export const sortSelector = (state): IPeopleSortType => state.people.sort;
export const openMobileNavSelector = (state): boolean => state.people.open_mobile_nav;
export const currentFilterSubmenuOpenSelector = (state): IPeopleFilterType | null =>
  state.people.current_filter_submenu_open;
export const currentBeaconStepSelector = (state): number => state.people.current_beacon_step;
export const showBeaconsSelector = (state): boolean => state.people.show_beacons;
export const shouldShowBeaconsSelector = (state): boolean => state.people.should_show_beacons;
export const showOnboardingModalSelector = (state): boolean => state.people.show_onboarding_modal;
export const forceScrollSidebarSelector = (state): boolean => state.people.force_scroll_sidebar;
export const contextSelector = (state): IPeopleContext => state.people.context;
export const selectedCriteriaSelector = (
  state
): { [key in DataTypePillType]: { name: string }[] } => state.people.selected_criteria;
export const forceSkeletonListSelector = (state): boolean => state.people.force_skeleton_list;

const selectPeople = (state): IPerson[] => state.people.people;
const selectDefaultPeople = (state): IPerson[] => state.people.default_people;
const selectUuids = (state): IPeopleUuids[] => state.people.uuids;
const originalPeople = (state): IPerson[] => state.people.original_people;
const selectDefaultUuids = (state): IPeopleUuids[] => state.people.default_uuids;

export const peopleSelector = createSelector([selectPeople, selectUuids], (people, uuids) => {
  return people.map((person) => {
    const uuid = uuids.find((u) => u.uuid === person.uuid);
    return {
      ...person,
      matches: uuid ? uuid.matches : 0,
      matched_elements: uuid?.matched_elements,
    };
  });
});

export const originalPeopleSelector = createSelector(
  [originalPeople, selectUuids],
  (people, uuids) => {
    return people.map((person) => {
      const uuid = uuids.find((u) => u.uuid === person.uuid);
      return {
        ...person,
        matches: uuid ? uuid.matches : 0,
        matched_elements: uuid?.matched_elements,
      };
    });
  }
);
export const defaultPeopleSelector = createSelector(
  [selectDefaultPeople, selectDefaultUuids],
  (people, uuids) => {
    return people.map((person) => {
      const uuid = uuids.find((u) => u.uuid === person.uuid);
      return {
        ...person,
        matches: uuid ? uuid.matches : 0,
        matched_elements: uuid?.matched_elements,
      };
    });
  }
);

export const fullCriteriaUuidsSelector = createSelector(
  [selectUuids, previousFiltersLengthSelector],
  (uuids, previous_filters_length) =>
    uuids.filter((p) => p.matches === previous_filters_length).map((p) => p.uuid)
);

export const partialCriteriaUuidsSelector = createSelector(
  [selectUuids, previousFiltersLengthSelector],
  (uuids, previous_filters_length) =>
    uuids.filter((p) => p.matches < previous_filters_length).map((p) => p.uuid)
);

export const allCurrentFiltersSelector = createSelector(
  [filtersSelector, exclusiveFiltersSelector],
  (filters, exclusive_filters): IPeopleFilters => {
    const inclusiveFilters = Object.keys(filters).reduce<IPeopleFilters>((acc, key) => {
      if (filters[key].length > 0) {
        return { ...acc, [key]: filters[key].map((f) => f.uuid) };
      }
      return acc;
    }, {});

    const allFilters = Object.keys(exclusive_filters).reduce<IPeopleFilters>((acc, key) => {
      if (exclusive_filters[key]) {
        return { ...acc, [key]: true };
      }
      return acc;
    }, inclusiveFilters);

    return allFilters;
  }
);

export const sortedUuidsSelector = createSelector([uuidsSelector, sortSelector], (uuids, sort) => {
  if (uuids.length === 0) return [];

  if (sort === 'relevance') {
    return [...uuids].sort((a, b) => b.matches - a.matches);
  }

  return [...uuids].sort((a, b) => a.name.localeCompare(b.name));
});

export const filtersNamesSelector = createSelector([filtersSelector], (filters) => {
  return Object.keys(filters).reduce((acc, key) => {
    if (filters[key].length > 0) {
      return [...acc, ...filters[key].map((f) => f.name)];
    }
    return acc;
  }, []);
});

export const allFiltersNamesSelector = createSelector(
  [filtersSelector, exclusiveFiltersSelector],
  (filters, exclusive_filters) => {
    if (!filters && !exclusive_filters) return [];
    let inclusiveFilters = [];

    if (filters) {
      inclusiveFilters = Object.keys(filters).reduce<string[]>((acc, key) => {
        if (filters[key].length > 0) {
          return [...acc, ...filters[key].map((f) => f.name)];
        }
        return acc;
      }, []);
    }

    const exclusiveFilters =
      Object.keys(exclusive_filters)?.reduce<string[]>((acc, key: IPeopleFilterType) => {
        if (exclusive_filters[key]) {
          return [...acc, exclusiveFilterToTitle.get(key)];
        }
        return acc;
      }, []) || [];

    return [...inclusiveFilters, ...exclusiveFilters];
  }
);

export const allPreviousFiltersNamesSelector = createSelector(
  [previousFiltersSelector],
  (filters) => {
    const inclusiveFilters = Object.keys(filters).reduce<string[]>((acc, key) => {
      if (filters[key].length > 0) {
        return [...acc, ...filters[key].map((f) => f.name)];
      }
      return acc;
    }, []);

    return inclusiveFilters;
  }
);

export const currentExclusiveFiltersActiveSelector = createSelector(
  [exclusiveFiltersSelector],
  (filters: IPeopleExclusiveFilters): IPeopleExclusiveFilterType[] => {
    return Object.keys(filters).reduce((acc, filterKey) => {
      if (filters[filterKey]) {
        acc.push(filterKey);
      }

      return acc;
    }, []);
  }
);

export const filteredUuidsSelector = createSelector(
  [uuidsSelector, selectedCriteriaSelector],
  (uuids, selectedCriteria) => {
    if (!selectedCriteria) return uuids;
    if (Object.values(selectedCriteria).length === 0) return uuids;

    return uuids.filter((uuid) => {
      return Object.keys(selectedCriteria).every((key) => {
        if (!uuid.matched_detail) return false;

        const matchedElementByType = uuid.matched_detail
          .filter((element) => element.type === key)
          .map((element) => element.name);

        if (matchedElementByType.length > 0) {
          if (selectedCriteria[key].length > 0) {
            return selectedCriteria[key].every((criteria) =>
              matchedElementByType.includes(criteria)
            );
          }
        } else {
          return false;
        }
      });
    });
  }
);

export const selectedCriteriaFiltersSelector = createSelector(
  [selectedCriteriaSelector],
  (selectedCriteria) => {
    if (!selectedCriteria) return [];
    return Object.keys(selectedCriteria).reduce((acc, key) => {
      return [...acc, ...selectedCriteria[key].map((name) => ({ type: key, name }))];
    }, []);
  }
);

export default slice.reducer;
